import React from "react";
import {Container, Nav, Navbar} from "react-bootstrap";

function Header() {
  return (
    <Navbar
      bg="light"
      expand="lg"
      id="navbar_top"
      className="appie-sticky sticky"
    >
      <Container>
        <Navbar.Brand href="#home">
        {/* <img src="../images/caipl-logo.png" alt="react logo" /> */}
         <h1 className="mb-0"><span style={{color: "#f04c4d"}}>SIVALICON</span></h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav " className="justify-content-end">
          <Nav className="">
            <Nav.Link href="#home" className="px-3">
              Home
            </Nav.Link>
            <Nav.Link href="#about" className="px-3">
              About Us
            </Nav.Link>
            <Nav.Link href="#service" className="px-3">
              Service
            </Nav.Link>
            <Nav.Link href="#career" className="px-3">
              Careers
            </Nav.Link>
            <Nav.Link href="#contact" className="px-3">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
