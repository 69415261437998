import React from "react";
import {Container, Row, Col, Form} from "react-bootstrap";

function Subscribe() {
  return (
    <Container>
      <Row className="justify-content-center margin-bottom">
        {
          <div class="appie-section-title text-center">
            <h3 class="title">
              Submit your info to receive our weekly updates
            </h3>
          </div>
        }
        <Col xs={12} md={6} className="text-center">
          <Form className=" my-4">
            <Form.Control
              type="search"
              placeholder="Email"
              className="me-2 p-3 border-0 border-bottom"
              aria-label="Search"
            />
          </Form>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              By checking, I agree to receive communication from Sivalicon. I
              can unsubscribe at any time.
            </label>
          </div>
          <a href="javascript:void(0)" class="secound-btn btn-subscribe">
            Stay Connected and Learn More
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Subscribe;
