import React from "react";
import {Container, Row, Col, Image} from "react-bootstrap";

function Partners() {
  return (
    <Container fluid className="partner margin-bottom" id="career">
      <Container>
        <Row>
          <Col xs={12} md={2} className="text-center"></Col>
          <Col xs={12} md={2} className="text-center">
            <Image src="../images/aws-640w.webp" fluid />
          </Col>
          <Col xs={12} md={2} className="text-center">
            <Image src="../images/mango-640w.webp" fluid />
          </Col>
          <Col xs={12} md={2} className="text-center">
            <Image src="../images/ms-640w.png" fluid />
          </Col>
          <Col xs={12} md={2} className="text-center">
            <Image src="../images/ui-path-640w.webp" fluid />
          </Col>
          <Col xs={12} md={2} className="text-center"></Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Partners;
