import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import Slider from "./Components/Slider";
import Section1 from "./Components/Home/Section1";
import Section2 from "./Components/Home/Section2";
import Partners from "./Components/Home/Partners";
import Subscribe from "./Components/Home/Subscribe";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

function App() {
  React.useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  return (
    <div className="App">
      <Header></Header>
      <Slider></Slider>
      <Section1></Section1>
      <Section2></Section2>
      <Partners></Partners>
      <Subscribe></Subscribe>
      <Footer></Footer>
    </div>
  );
}

export default App;
