import React from "react";

function Slider() {
  return (
    <div class="container appie-hero-area" id="home">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="appie-hero-content appie-hero-content-4">
            <span>Sivalicon</span>
            <h1 class="appie-title">
              App Development & Digital Transformation Solution
            </h1>
            <p>
            We create a 360° value for businesses by harnessing technology & solutions.
            </p>
            <a href="javascript:void(0)" class="main-btn me-3">
              Get Started
            </a>
            <a href="javascript:void(0)" class="secound-btn">
              Get Started
            </a>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="appie-hero-thumb-4 text-center">
            <img className="appie-hero-thumb" src="../images/11.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider;
