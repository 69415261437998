import React from "react";
import {Container, Row, Col, Image} from "react-bootstrap";

function Footer() {
  return (
    <Container fluid className="bg-color" id="contact">
      <Container>
        <Row className="g-5 py-5">
          <Col xs={12} md={1} className="text-white"></Col>

          <Col xs={12} md={5} className="text-white">
            <h4>About Us</h4>
           <h1 style={{color: "#f04c4d"}}>SIVALICON</h1>
            <p>
              <Image
                className="mb-4 icons me-2"
                src="../images/facebook.png"
                fluid
              />
              <Image
                className="mb-4 icons me-2"
                src="../images/twitter.png"
                fluid
              />
              <Image
                className="mb-4 icons me-2"
                src="../images/insta.png"
                fluid
              />
              <Image
                className="mb-4 icons me-2"
                src="../images/youtube.png"
                fluid
              />
              <Image
                className="mb-4 icons me-2"
                src="../images/linkedin.png"
                fluid
              />
            </p>
            <p class="text-white">
              Copyright © 2024 Sivalicon. All rights reserved.
            </p>
          </Col>
          <Col xs={12} md={3} className="text-white">
            <h4>India:</h4>

            <p class="text-white">
              1st Floor, Natesan Avenue,
              <br />
              No 4, Karapakkam, Chennai,
              <br />
              Tamil Nadu 600097.
            </p>
            <p class="text-white">info@sivalicon.com</p>
            <p class="text-white">+91-733-942-6667</p>
          </Col>

          <Col xs={12} md={3} className="text-white">
            <h4>India:</h4>

            <p class="text-white">
              1st Floor, Natesan Avenue,
              <br />
              No 4, Karapakkam, Chennai,
              <br />
              Tamil Nadu 600097.
            </p>
            <p class="text-white">info@sivalicon.com</p>
            <p class="text-white">+91-733-942-6667</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Footer;
