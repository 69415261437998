import React from "react";

function Section1() {
  return (
    <section id="about">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 ">
            <div class="appie-section-title mb-30 text-center">
              <h3 class="appie-title">
                Are you looking for reliable support that can help <br />
                your business to drive success?
              </h3>
              <p>
              Sivalicon is the right partner for you if you're looking to create an integrated technology quickly and efficiently. We've worked with a variety of clients, from startups to enterprise companies, and we have the experience and expertise to get the job done right. Contact us today, and let's get started!
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-4">
            <div class="appie-about-8-box">
              <h3 class="title">Product Engineering</h3>
              <p>
              Product engineering is a multidisciplinary field<br/> that focuses on the development.
              </p>
              <a href="javascript:void(0)" class="main-btn">
                Learn More <i class="fal fa-arrow-right"></i>
              </a>
              <div class="thumb mr-30">
                <img className="image-box" src="../images/image-1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-6  mb-4">
            <div class="appie-about-8-box">
              <h3 class="title">Data Engineering</h3>
              <p>
              We encompass all aspects of data science,<br/> from data collection to data visualization.
              </p>
              <a href="javascript:void(0)" class="main-btn">
                Learn More <i class="fal fa-arrow-right"></i>
              </a>
              <div class="thumb mr-30">
                <img className="image-box" src="../images/image-2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-6  mb-4">
            <div class="appie-about-8-box">
              <h3 class="title">Digital Innovation</h3>
              <p>
              We have expertise in data technologies who <br/>can help our clients rapidly build, train.
              </p>
              <a href="javascript:void(0)" class="main-btn">
                Learn More <i class="fal fa-arrow-right"></i>
              </a>
              <div class="thumb mr-30">
                <img className="image-box" src="../images/image-3.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-6  mb-4">
            <div class="appie-about-8-box">
              <h3 class="title">Security Risk Compliance</h3>
              <p>
              We help businesses reduce the risk of having<br/> to deal with disruptive challenges. 
              </p>
              <a href="javascript:void(0)" class="main-btn">
                Learn More <i class="fal fa-arrow-right"></i>
              </a>
              <div class="thumb mr-30">
                <img className="image-box" src="../images/image-4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section1;
