import React from "react";
import {Container, Row, Col, Image} from "react-bootstrap";

function Section2() {
  return (
    <Container className=" margin-top" id="service">
      <div class="row justify-content-center">
        <div class="col-lg-10 ">
          <div class="appie-section-title mb-30 text-center">
            <h3 class="appie-title">
              Thinking about connecting with our experts?
            </h3>
            <h4>Meet our subject matter experts!</h4>
            <p>
            At Sivalicon, we have a team of experienced and dedicated individuals who are well-versed in the latest technologies and trends. Supported by state-of-the-art infrastructure and cutting-edge tools, we are capable of developing comprehensive solutions that meet all your requirements.
            </p>
          </div>
        </div>
      </div>
      <Row className="align-items-center margin-bottom g-5">
        <Col xs={12} md={6} className="text-center">
          <Image className="img-height" src="../images/hire-us.png" fluid />;
        </Col>

        <Col xs={12} md={6}>
          <h2 className="h2 pb-3 mb-2 mb-md-3">Why Hire Us?</h2>
          <p>
          Sivalicon is one of the top healthtech business solutions provider companies in India. Our team specializes in creating experiences that connect people and drive meaningful outcomes for our clients' businesses. 
          </p>
         <p>From conceptualization to monetization, we have got you covered with a comprehensive array of digital services that will help you grow.</p>
        </Col>
      </Row>

      <Row className="align-items-center margin-bottom g-5">
        <Col xs={12} md={6}>
          <h2 className="h2 pb-3 mb-2 mb-md-3">Software Product Engineering</h2>
          <p>
          Software product engineering encompasses the systematic and multidisciplinary approach to developing innovative software products. It begins with a deep understanding of user needs, market dynamics, and technological possibilities, guiding the conceptualization phase.
          </p>
          <p>
          Through meticulous planning, requirements gathering, and roadmap creation, the software's scope and features are defined. 
          Design and architecture phases follow, focusing on creating intuitive user experiences and robust technical frameworks. 
          </p>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <Image className="img-height" src="../images/Software-product.png" fluid />;
        </Col>
      </Row>
      <Row className="align-items-center margin-bottom g-5">
        <Col xs={12} md={6} className="text-center">
          <Image className="img-height" src="../images/image-2.png" fluid />;
        </Col>

        <Col xs={12} md={6}>
          <h2 className="h2 pb-3 mb-2 mb-md-3">Data Engineering</h2>
          <p>
          We encompass all aspects of data science, from data collection to data visualization. By working with a team of experienced data engineers, you can ensure that your data is properly processed and stored and that it is accessible to the people who need it.
          </p>
          <p>
          Data engineering involves the intricate orchestration and transformation of raw data into a structured and usable format, laying the foundation for robust data-driven solutions.
          </p>
        </Col>
      </Row>
      <Row className="align-items-center margin-bottom g-5">
        <Col xs={12} md={6}>
          <h2 className="h2 pb-3 mb-2 mb-md-3">Data Innovation for Enterprises</h2>
          <p>
          We help organizations in creating their digital journey by creating transformational value to their customer experience. We have expertise in data technologies who can help our clients rapidly build, train, and deploy machine learning models. 
          </p>
          <p>
          Data engineering involves the intricate orchestration and transformation of raw data into a structured and usable format, laying the foundation for robust data-driven solutions.
          </p>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <Image className="img-height" src="../images/image-3.png" fluid />;
        </Col>
      </Row>
      <Row className="align-items-center margin-bottom g-5">
        <Col xs={12} md={6} className="text-center">
          <Image className="img-height" src="../images/image-4.png" fluid />;
        </Col>

        <Col xs={12} md={6}>
          <h2 className="h2 pb-3 mb-2 mb-md-3">Security, Risk & Compliance</h2>
          <p>
          We help businesses reduce the risk of having to deal with disruptive challenges. Overall governance, enterprise risk management, and regulatory compliance are the areas where we can assist organizations.
          </p>
          <p>
          Security, risk, and compliance form the core pillars of a robust and resilient framework that organizations implement to safeguard their operations, data, and reputation in an increasingly complex digital landscape.
          </p>
        </Col>
      </Row>
      <Row className="align-items-center margin-bottom g-5">
        <Col xs={12} md={6}>
          <h2 className="h2 pb-3 mb-2 mb-md-3">Discovery Services</h2>
          <p>
          We are committed to creating a seamless experience to the clients by providing the best possible discovery service. We are constantly striving to improve our discovery services by incorporating the cutting edge technologies and industrial trends. Our goal is to provide a discovery service that is easy to use, reliable, and comprehensive.
          </p>
          <p>
          Discovery services refer to a range of processes and technologies used to search, locate, access, and retrieve information or resources within a given system or network.
          </p>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <Image className="img-height" src="../images/Discovery.png" fluid />;
        </Col>
      </Row>
    </Container>
  );
}

export default Section2;
